@font-face {
  font-family: 'SofiaProLight';
  src: url('/assets/fonts/SofiaProLight-webfont.eot');
  src: url('/assets/fonts/SofiaProLight-webfont.eot?#iefix') format("embedded-opentype"), url('/assets/fonts/SofiaProLight-webfont.woff2') format("woff2"), url('/assets/fonts/SofiaProLight-webfont.woff') format("woff"), url('/assets/fonts/SofiaProLight-webfont.ttf') format("truetype"), url('/assets/fonts/SofiaProLight-webfont.svg#sofia_prolight') format("svg");
  font-weight: normal;
  font-style: normal;
}


/*================================
=            Reset Settings       =
================================*/

p, h1, h2, h3, h4, h5 {
  margin: 0;
}

a {
  &:hover, &:active, &:focus, &:visited {
    text-decoration: none;
  }
}

.btn {
  &.active {
    &.focus, &:focus {
      outline: none;
    }
  }
  &.focus {
    outline: none;
  }
  &:active {
    &.focus, &:focus {
      outline: none;
    }
  }
  &:focus {
    outline: none;
  }
}

/*================================
=            Main Settings       =
================================*/

h1 {
  font-family: 'Oswald', sans-serif;
}

body {
  font-family: 'sofia-pro',sans-serif;
}

main {
  min-height: 100vh;
  background: -webkit-linear-gradient(127deg, #6B60C4, #CF92E0);
  background: -moz-linear-gradient(127deg, #6B60C4, #CF92E0);
  background: -o-linear-gradient(127deg, #6B60C4, #CF92E0);
  background: linear-gradient(127deg, #6B60C4, #CF92E0);
}

input[type="password"] {
  font-size: 30px;
}

/*================================
=            Form Settings       =
================================*/

.form-wrapper {
  width: 100%;
  max-width: 530px;
  padding-top: 7.25rem;
}

.form {
  width: 100%;
  float: none;
  margin: auto;
  padding: 66px 62px 60px 62px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
}

/*Logo*/

.logo-box img {
  float: none;
  margin: auto;
}

/*Form Heading*/

.form-heading {
  font-weight: normal;
  line-height: 1.39;
  letter-spacing: 1.4px;
  text-align: center;
  color: #816bca;
}

.form-group {
  margin-top: 40px;
}

/********************************Typography*/

.form.signup {
  text-transform: uppercase;
}

/*Default label color*/

.form-group.label-floating {
  label.control-label {
    font-size: 14px;
    letter-spacing: 4.3px;
    color: #8f8f8f;
  }
  &.is-focused label.control-label {
    font-weight: 300;
    color: #e3e3e3;
  }
  &:not(.is-empty) label.control-label {
    color: #e3e3e3;
  }
}

/*Focused label color*/

/*If not empty label color*/

/*Gradients on input border*/

.form-control, .form-group .form-control {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)), -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)) !important;
  background-image: -moz-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)), -moz-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)) !important;
  background-image: -o-gradient-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)), -o-gradient-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)) !important;
  background-image: -ms-gradient-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)), -ms-gradient-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f7f7f7)) !important;
  background-image: linear-gradient(#f7f7f7, #f7f7f7), linear-gradient(#f7f7f7, #f7f7f7) !important;
}

/*input form-control*/

.form-control {
  height: 43px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.5px;
  color: #8f8f8f;
  padding-top: 0;
  padding-bottom: 0;
}

/*Input valid*/

.form-group {
  &.input-valid {
    background: url('/assets/img/validation-tick.svg') no-repeat right bottom 60%;
  }
  &.input-error {
    background: url('/assets/img/validation-alert.svg') no-repeat right bottom 60%;
    background: url('/assets/img/validation-tick.svg') no-repeat right bottom 60%;
    background: url('/assets/img/validation-alert.svg') no-repeat right bottom 60%;
    &.is-focused .form-control {
      background-image: -webkit-linear-gradient(#f0aba7, #f0aba7), -webkit-linear-gradient(#f7f7f7, #f7f7f7) !important;
      background-image: -moz-linear-gradient(#f0aba7, #f0aba7), -moz-linear-gradient(#f7f7f7, #f7f7f7) !important;
      background-image: -o-linear-gradient(#f0aba7, #f0aba7), -o-linear-gradient(#f7f7f7, #f7f7f7) !important;
      background-image: -ms-linear-gradient(#f0aba7, #f0aba7), -ms-linear-gradient(#f7f7f7, #f7f7f7) !important;
      background-image: linear-gradient(#f0aba7, #f0aba7), linear-gradient(#f7f7f7, #f7f7f7) !important;
    }
  }
}

/*Input error*/

/*Input error*/

/*Input error line color*/

/*Have an account?*/

.form-help {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 4px;
  color: #886ecc;
  margin-top: 39px;
  > a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 4px;
    color: #886ecc;
    margin-top: 39px;
  }
}

/*dimensions and color of tooltip */

.blue-tooltip + .tooltip > {
  .tooltip-inner {
    font-family: 'Montserrat', sans-serif;
    background-color: #afafaf;
    min-width: 200px;
    max-width: 200px;
    text-align: left;
  }
  .tooltip-arrow {
    border-right-color: #afafaf;
    border-left-color: #afafaf;
  }
}

/*arrow color*/

/*************************End of Typography*/

/*Button styles*/

.btn-submit {
  width: 100%;
  padding: 30px 0 32px 0;
  margin: 67px auto 0 auto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 5px;
  border-radius: 100px;
  background-color: #886ecc;
}

.btn {
  &:not(.btn-raised) {
    color: #ffffff;
    &:not(.btn-link) {
      &:hover, &:focus {
        background-color: #886ecc;
        opacity: 1;
        color: #fff;
      }
    }
  }
  &[disabled], &:disabled, &[disabled][disabled] {
    background-color: #886ecc;
    opacity: 1;
    color: #fff;
  }
  &:not(.btn-raised):not(.btn-link) {
    &:hover, &:focus {
      opacity: 1;
      color: #fff;
      background-color: #886ecc;
    }
  }
  &[disabled], &:disabled, &[disabled][disabled] {
    background-color: #F1EEF9;
    opacity: 1;
    color: #fff;
    &:hover {
      background-color: #F1EEF9;
      opacity: 1;
      color: #fff;
    }
  }
}

/*End of Button styles*/

#copyright {
  margin-top: 4.125rem;
  font-family: 'SofiaProLight',sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 7;
  letter-spacing: 2.4px;
  color: #ffffff;
  text-align: center;
}

.logo-box {
  cursor: default;
  a {
    cursor: default;
  }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  color: #8f8f8f !important;
}

@media only screen and (min-width: 770px) and (max-width: 1440px) {
  .form-heading {
    font-size: 28px;
  }
  .form {
    width: 85%;
  }
  .form-group.label-floating label.control-label {
    font-size: 12px;
  }
  .btn-submit {
    font-size: 12px;
    padding: 25px 0 27px 0;
    margin: 55px auto 0 auto;
  }
  .form-help {
    font-size: 10px;
    > a {
      font-size: 10px;
    }
  }
  .form-text {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .attempts .form-warning {
    font-size: 13px;
    letter-spacing: 3px;
  }
}

@media only screen and (max-width: 769px) {
  h1 {
    font-size: 2.25rem;
  }
  input[type="password"] {
    font-size: 1rem;
  }
  .form {
    padding: 4.125rem 3.875rem 3.75rem  3.875rem;
  }
  .form-wrapper {
    max-width: 33.13rem;
  }
  .logo-box img {
    width: 18.75rem;
  }
  .form-group {
    &.label-floating {
      label.control-label {
        font-size: 0.875rem;
        letter-spacing: 0.25rem;
      }
      &.is-focused label.control-label {
        font-size: 0.875rem;
      }
    }
    margin-top: 2.5rem;
  }
  .form-control {
    height: 2.688rem;
    font-size: 1rem;
  }
  .form-help {
    font-size: 0.75rem;
    margin-top: 2.438rem;
    > a {
      font-size: 0.75rem;
      margin-top: 2.438rem;
    }
  }
  .btn-submit {
    padding: 1.875rem 0 2rem 0;
    margin: 4.188rem auto 0 auto;
    font-size: 0.875rem;
    letter-spacing: 0.3125rem;
    border-radius: 6.25rem;
  }
  #copyright {
    font-size: 0.625rem;
    letter-spacing: 0.125rem;
  }
}

/*================================
=     End of Form Settings       =
================================*/

/*================================
=       Sent Validate Settings   =
================================*/

.sentvalidate {
  padding: 47px 62px 71px 62px;
  text-align: center;
  .form-heading {
    margin-top: 30px;
  }
}

.form-text {
  font-family: 'SofiaProLight';
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 4.3px;
  color: #a4a4a4;
  margin-top: 17px;
  padding-left: 50px;
  padding-right: 50px;
}

.sentvalidate .btn-submit {
  margin-top: 36px;
}

@media only screen and (max-width: 769px) {
  .sentvalidate {
    padding: 2.938rem 3.875rem  4.438rem 3.875rem;
  }
  .cat-box img {
    width: 4.563rem;
  }
  .sentvalidate .form-heading {
    margin-top: 1.875rem;
  }
  .form-text {
    font-size: 0.75rem;
    letter-spacing: 0.25rem;
    margin-top: 1.063rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .sentvalidate .btn-submit {
    margin-top: 2.25rem;
  }
}

/*================================
= End of  Sent Validate Settings =
================================*/

/*================================
=              Sign In           =
================================*/

.form-help.pwd {
  text-align: left;
}

/*================================
=      Incorrect Attempts        =
================================*/

.attempts {
  img {
    width: 39.1px;
    height: 34.7px;
  }
  .form-warning {
    font-weight: 400;
    color: #886ecc;
    margin-top: 22px;
  }
}

@media only screen and (min-width: 1441px) {
  .attempts .form-warning {
    font-size: 14px;
    letter-spacing: 5px;
  }
}

@media only screen and (max-width: 769px) {
  .attempts {
    img {
      width: 2.438rem;
      height: 2.125rem;
    }
    .form-warning {
      font-size: 0.800rem;
      letter-spacing: 0.3125rem;
      margin-top: 1.375rem;
    }
  }
  .form-group.input-error {
    background-position: right bottom 80%;
  }
}

/*================================
=      Forgot Pass               =
================================*/

.forgotpass .form-text {
  text-align: center;
  padding-left: 45px;
  padding-right: 45px;
}